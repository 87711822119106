
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { HygrometerDetails, HygrometerItem, LITE_FEATURE } from "@/domain";

import { IHygrometerService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class HygrometersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get hygrometerService() {
    return this.container.resolve<IHygrometerService>(S.HYGROMETER_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.hygrometers, (h) => h.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/HygrometerCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/HygrometerUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  hygrometers: HygrometerItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.hygrometers = await this.hygrometerService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(hygrometer: HygrometerDetails) {
    this.hygrometers.push(HygrometerItem.fromDetails(hygrometer));
  }

  update(hygrometer: HygrometerDetails) {
    const index = _.findIndex(this.hygrometers, (h) => h.id == hygrometer.id);
    if (index != -1) {
      this.hygrometers.splice(index, 1, HygrometerItem.fromDetails(hygrometer));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const hygrometer = _.find(this.hygrometers, (h) => h.id == id)!;
      const result = await this.hygrometerService.update(id, {
        serialNumber: hygrometer.serialNumber!,
        equipmentType: hygrometer.equipmentType!,
        equipmentBrandId: hygrometer.equipmentBrand.id!,
        pvNumber: hygrometer.pvNumber!,
        disabled: !hygrometer.disabled!,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
